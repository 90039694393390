import React, { useMemo } from 'react';
import { Box, Container } from 'theme-ui';

import { WorkPageProps } from './types';
import WorkpageHome from './Home';
import { getWorkFilters, getWorkItems } from './utils';
import SEO from '../../atoms/SEO';

const WorkBody: React.FC<WorkPageProps> = ({
  data,
}) => {
  const items = useMemo(() => getWorkItems(data.works.caseStudies), [
    data.works.caseStudies,
  ]);

  const filters = useMemo(() => getWorkFilters(data.categories.nodes), [
    data.categories.nodes,
  ]);

  return (
    <Box variant="pages.page.pageWrapper" className="page-wrapper">
      <Box variant="pages.page.pageWrapper.content">
        <SEO
          title={data.works?.seoTitle}
          description={data.works?.seoDescription}
          imageUrl={data.works?.seoImage?.asset?.fluid?.src}
        />
        <Container>
          <WorkpageHome
            {...{
              items,
              filters,
            }}
          />
        </Container>
      </Box>
    </Box>
  );
};

export default WorkBody;
