import React, { useEffect, useState, useCallback, useMemo } from 'react';
import queryString from 'query-string';
import { useLocation } from '@reach/router';
import WorkBody from '../../components/pages/work/WorkBody';
import { IWorksList, IWorksPageData } from '../../components/pages/work/types';
import { ICategory } from '../../apiTypes';
import { getFilteredCategories } from '../../components/pages/work/utils';
import { client } from '../../utils/client';

const queryCategoriesList = `*[_type == "category"] | order(title asc){
    "id": _id,
    title,
    slug,
}`;
const query = `*[_type == "category" && slug.current == $slug]{
    caseStudies[]{
      _type,
      "caseStudy": caseStudy[]->{
        _id,
        darkBackground,
        clientName,
        title,
        slug,
        "mainImage": {
          "asset": {
              "fluid": {
                  "src": mainImage.asset->url,
                  "aspectRatio": mainImage.asset->metadata.dimensions.aspectRatio
              }
          }
        },
      },
      "caseStudySecond": caseStudySecond[]->{
        _id,
        darkBackground,
        clientName,
        title,
        slug,
        "mainImage": {
          "asset": {
              "fluid": {
                  "src": mainImage.asset->url,
                  "aspectRatio": mainImage.asset->metadata.dimensions.aspectRatio
              }
          }
        },
      },
    },
  }
`;

const PreviewWorks = (): React.ReactNode => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({} as IWorksPageData);
  const location = useLocation();

  const slug = useMemo(() => queryString.parse(location.search).slug, [
    location,
  ]);

  const fetchData = useCallback(async () => {
    const [dataWorks, dataCategories]: [
      IWorksList[],
      ICategory[],
    ] = await Promise.all([
      client.fetch(query, {
        slug,
      }),
      client.fetch(queryCategoriesList),
    ]);

    setData({
      works: dataWorks[dataWorks.length - 1],
      categories: {
        nodes: getFilteredCategories(dataCategories),
      },
    });
    setLoading(false);
  }, [slug]);

  useEffect(() => {
    const subscribeData = client
      .listen(query, { slug }, { visibility: 'query', includeResult: false })
      .subscribe(() => {
        setLoading(true);
        fetchData();
      });

    return () => subscribeData.unsubscribe();
  }, [fetchData, slug]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading && !Object.entries(data).length) {
    return <div>Loading ...</div>;
  }

  return <WorkBody data={data} />;
};

export default PreviewWorks;
