import React from 'react';
import { Flex } from 'theme-ui';
import ThumbnailTile from '../../molecules/ThumbnailTile';
import { WorkListingProps } from './types';
import RevealAnimationWrapper from '../../atoms/RevealAnimationWrapper';

const WorkListing: React.FC<WorkListingProps> = ({ items }) => (
  <Flex as="ul" variant="workListing.list">
    {items.map(({ key, small, ...item }) => (
      <Flex
        as="li"
        {...{ key }}
        className={`thumbnail-list-item ${
          small ? 'thumbnail-list-item-small' : ''
        }`}
        variant={small ? 'workListing.listItemSmall' : 'workListing.listItem'}
      >
        <RevealAnimationWrapper style={{ width: '100%' }}>
          <ThumbnailTile {...{ small, ...item }} />
        </RevealAnimationWrapper>
      </Flex>
    ))}
  </Flex>
);

export default WorkListing;
