// import { motion } from 'framer-motion';
import React from 'react';
import { Box } from 'theme-ui';
import RevealAnimationWrapper from '../../../atoms/RevealAnimationWrapper';
import PageFilters from '../../../molecules/PageFilters';
import WorkListing from '../../../organisms/WorkListing';
import { WorkpageHomeProps } from './types';

// const variants = {
//   visible: { opacity: 1 },
//   hidden: { opacity: 0 },
// };

const WorkpageHome: React.FC<WorkpageHomeProps> = ({
  items,
  filters = [],
  // transitionStatus,
}) => (
  <Box>
    <RevealAnimationWrapper>
      {/* <motion.div
          {...{ variants }}
          animate={
            transitionStatus &&
            ['entering', 'entered'].includes(transitionStatus)
              ? 'visible'
              : 'hidden'
          }
        > */}
      <PageFilters items={filters} />
      {/* </motion.div> */}
    </RevealAnimationWrapper>
    <WorkListing {...{ items }} />
  </Box>
);

export default WorkpageHome;
